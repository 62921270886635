<template>
  <v-card :loading="submitting">
    <v-card-title>
      Register
    </v-card-title>
    <Loading
      :loading="loading"
      message="Loading"
    />
    <v-form
      v-if="!loading && !success"
      ref="form"
      class="register"
      @submit.prevent="register"
    >
      <v-card-text>
        <v-alert
          v-for="error in errorMessages"
          :key="error"
          type="error"
        >
          {{ error }}
        </v-alert>
        <v-text-field
          v-if="inviteRequired"
          v-model="credentials.invite"
          :disabled="submitting"
          :rules="inviteRules"
          required
          type="text"
          label="Invite Code"
        />
        <v-text-field
          v-model="credentials.email"
          :disabled="submitting"
          :rules="emailRules"
          required
          type="email"
          label="Email"
        />
        <v-text-field
          v-model="credentials.email2"
          :disabled="submitting"
          :rules="emailMatchRules"
          required
          type="email"
          label="Repeat Email"
        />
        <v-text-field
          v-model="credentials.username"
          :disabled="submitting"
          :rules="usernameRules"
          required
          type="text"
          label="Username"
        />
        <v-text-field
          v-model="credentials.password1"
          :disabled="submitting"
          :rules="passwordRules"
          required
          type="password"
          label="Password"
        />
        <v-text-field
          v-model="credentials.password2"
          :disabled="submitting"
          :rules="passwordMatchRules"
          required
          type="password"
          label="Repeat Password"
        />
        <v-text-field
          v-if="acceptReferrer"
          v-model="credentials.referrer"
          :disabled="submitting"
          :rules="referrerRules"
          type="text"
          label="Referrer"
          hint="Optionally enter the username of the user who referred you"
          persistent-hint
        />
        <v-checkbox
          v-if="termsRequired"
          v-model="credentials.terms"
          :disabled="submitting"
          :rules="termsRules"
          required
        >
          <span slot="label">Agree to <router-link :to="{path: '/terms/'}">Terms and Conditions</router-link></span>
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="submitting"
          type="submit"
        >
          Register
        </v-btn>
        <v-btn
          v-if="waitlistOpen"
          :to="{name: 'Waitlist'}"
        >
          Request an Invite
        </v-btn>
      </v-card-actions>
    </v-form>
    <v-card-text v-else-if="success">
      <p>Registration successful. Please follow the activation link in your email to confirm your address before logging in.</p>
    </v-card-text>
  </v-card>
</template>

<script>
import {allianceBaseURL} from '@/api'
import { emailRules, passwordRules, usernameRules } from '@/utils/rules'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: true,
      credentials: {
        email : '',
        username : '',
        password1 : '',
        password2 : '',
        invite : '',
        referrer : '',
        terms : false,
      },
      inviteRequired: false,
      acceptReferrer: false,
      termsRequired: false,
      waitlistOpen: false,
      submitting: false,
      success: null,
      errorMessages: null,
      usernameRules: usernameRules,
      passwordRules: passwordRules,
      emailRules: emailRules,
      inviteRules: [
        v => !!v || 'Field is required',
      ],
      referrerRules: [
        v => v.length < 150 || 'Referrer username must be less than 150 characters',
        v => (!v || /^[\w.+-]+$/.test(v)) || 'Referrer username may contain only letters, numbers, and ./+/-/_ characters',
      ],
      termsRules: [
        v => !!v || 'You must agree to the terms and conditions to proceed',
      ],
      emailMatchRules: [
        v => !!v || 'Field is required',
        v => (!!v && v) === this.credentials.email || 'Emails do not match'
      ],
      passwordMatchRules: [
        v => !!v || 'Field is required',
        v => (!!v && v) === this.credentials.password1 || 'Passwords do not match'
      ],
    }
  },
  created: function () {
    this.loading = true
    const queryInvite = this.$route.query.invite
    if (queryInvite) {
      this.credentials.invite = queryInvite
    }
    const queryReferrer = this.$route.query.referrer
    if (queryReferrer) {
      this.credentials.referrer = queryReferrer
    }
    const queryUsername = this.$route.query.username
    if (queryUsername) {
      this.credentials.username = queryUsername
    }
    this.$http.get(allianceBaseURL + '/alliance/registration-status/')
      .then((response) => {
        this.inviteRequired = response.data.invite_required
        this.waitlistOpen = response.data.waitlist_open
        this.termsRequired = response.data.terms_required
        this.acceptReferrer = response.data.accept_referrer
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    register: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = null
      this.errorMessages = null
      const data = {
        email: this.credentials.email,
        username: this.credentials.username,
        password1: this.credentials.password1,
        password2: this.credentials.password2,
        invite: this.credentials.invite,
        referrer: this.credentials.referrer,
        terms: this.credentials.terms,
      }
      return this.$http.post(allianceBaseURL + '/auth/registration/', data)
        .then((response) => {
          console.log('success!')
          console.log(response)
          this.success = true
          this.credentials = {
            email : '',
            username : '',
            password1 : '',
            password2 : '',
            invite : '',
            referrer : '',
          }
        })
        .catch((error) => {
          console.log('error!')
          console.log(error.response.data)
          this.errorMessages = []
          if (error.response.data !== null && typeof error.response.data === 'object') {
            for (var property in error.response.data) {
              this.errorMessages = this.errorMessages.concat(error.response.data[property])
            }
          } else {
            this.errorMessages.push('Something went horribly wrong.')
          }
          this.success = false
        })
        .finally(() => this.submitting = false)
    }
  },
}
</script>
